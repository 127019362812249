import AdTechFX from './logos/adtech.svg';
import AIConversationsFX from './logos/aiconversations.svg';
import AnalyticsFX from './logos/analytics.svg';
import AnalyticsFX_TopNav from './logos/analytics-topnav.svg';
import CallTrackerFX from './logos/calltracker.svg';
import CDPFX from './logos/cdp.svg';
import CompanyTrackerFX from './logos/companytracker.svg';
import CompanyTrackerFXPro from './logos/companytrackerpro.svg';
import CompetitorSpyFX from './logos/competitorspy.svg';
import CompetitorSpyFX_TopNav from './logos/competitorspy-topnav.svg';
import ContentAnalyticsFX from './logos/contentanalytics.svg';
import ContentGeniusFX from './logos/contentgenius.svg';
import ContentGeniusFX_TopNav from './logos/contentgenius-topnav.svg';
import ContentZeitgeistFX from './logos/contentzeitgeist.svg';
import ConversationIntelFX from './logos/conversationintel.svg';
import Customer360FX from './logos/customer360.svg';
import DeepLearningFX from './logos/deeplearning.svg';
import EmailMarketingFX from './logos/emailmarketing.svg';
import FunnelsFX from './logos/funnels.svg';
import GamePlanFX from './logos/gameplan.svg';
import HistoryFX from './logos/history.svg';
import InboxFX from './logos/inbox.svg';
import IntegrationsFX from './logos/integrations.svg';
import IntegrationsFXLinkedInAudienceTargeting from './logos/integrations-linkedin-audience-targeting.svg';
import LeadManagerFX from './logos/leadmanager.svg';
import LeadScorerFX from './logos/leadscorer.svg';
import LeadScorerFXPro from './logos/leadscorerpro.svg';
import LocalFX from './logos/local.svg';
import MarketingCloudFX from './logos/marketingcloud.svg';
import MarketingCloudFX_TopNav from './logos/marketingcloud-topnav.svg';
import Nutshell from './logos/nutshell.svg';
import Nutshell_TopNav from './logos/nutshell-topnav.svg';
import OmniSEO from './logos/omniseo.svg';
import PersonalizeFX from './logos/personalize.svg';
import PlaybookFX from './logos/playbook.svg';
import PlaybookFX_TopNav from './logos/playbook-topnav.svg';
import ProspectorFX from './logos/prospector.svg';
import RevenueCloudFX from './logos/revenuecloud.svg';
import ReviewAccelerationFX from './logos/reviewacceleration.svg';
import SchedulerFX from './logos/scheduler.svg';
import TeamAI from './logos/teamai.svg';
import TeamAI_TopNav from './logos/teamai-topnav.svg';
import TeamworkFX from './logos/teamwork.svg';
import TeamworkFX_TopNav from './logos/teamwork-topnav.svg';

const productLogos = {
  AdTechFX,
  AIConversationsFX,
  AnalyticsFX,
  AnalyticsFX_TopNav,
  CallTrackerFX,
  CDPFX,
  CompanyTrackerFX,
  CompanyTrackerFXPro,
  CompetitorSpyFX,
  CompetitorSpyFX_TopNav,
  ContentAnalyticsFX,
  ContentGeniusFX,
  ContentGeniusFX_TopNav,
  ContentZeitgeistFX,
  ConversationIntelFX,
  Customer360FX,
  DeepLearningFX,
  EmailMarketingFX,
  FunnelsFX,
  GamePlanFX,
  HistoryFX,
  InboxFX,
  IntegrationsFX,
  IntegrationsFXLinkedInAudienceTargeting,
  LeadManagerFX,
  LeadScorerFX,
  LeadScorerFXPro,
  LocalFX,
  MarketingCloudFX,
  MarketingCloudFX_TopNav,
  Nutshell,
  Nutshell_TopNav,
  OmniSEO,
  PersonalizeFX,
  PlaybookFX,
  PlaybookFX_TopNav,
  ProspectorFX,
  RevenueCloudFX,
  ReviewAccelerationFX,
  SchedulerFX,
  TeamAI,
  TeamAI_TopNav,
  TeamworkFX,
  TeamworkFX_TopNav,
};

const productIdMappings = {
  adtech: 'AdTechFX',
  analytics: 'AnalyticsFX',
  c360fx: 'Customer360FX',
  calltrack: 'CallTrackerFX',
  cgfx: 'ContentGeniusFX',
  comptrack: 'CompanyTrackerFX',
  lmfx: 'LeadManagerFX',
  mcfx: 'RevenueCloudFX',
  rcfx: 'RevenueCloudFX',
  nutshell: 'Nutshell',
  playbook: 'PlaybookFX',
  prospector: 'ProspectorFX',
  twfx: 'TeamworkFX',
  teamai: 'TeamAI',
};

/**
 * Renders a product logo based on the provided product name.
 * @param {object} props - The component props.
 * @param {string} props.product - The name or ID of the product.
 * @param {string} [props.height] - The height of the logo container.
 * @param {string} [props.variant] - The variant of the logo (e.g. 'top-nav').
 * @param {object} [props.style]
 * @returns The rendered product logo component.
 */
const ProductLogo = ({ product, height, variant = null, style = {}, ...passThroughProps }) => {
  let defaultHeight = '22px';
  // Support passing product ID or name
  const productName = productIdMappings[product] || product;
  let DynamicLogo = productLogos[productName];

  if (variant === 'top-nav') {
    DynamicLogo = productLogos[`${productName}_TopNav`];
    defaultHeight = '30px';
  }

  if (!DynamicLogo) {
    return null;
  }

  return (
    <div
      style={{
        height: height || defaultHeight,
        width: 'max-content',
        transition: 'color 0.25s ease-in-out',
        ...style,
      }}
      {...passThroughProps}
    >
      <DynamicLogo className="w-auto h-100" />
    </div>
  );
};

export default ProductLogo;
